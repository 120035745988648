import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const FadeInFromBottom = ({ children, delay = 0.5, ...props }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: false, // Многоразовая анимация при каждом появлении
  });

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, delay },
      });
    } else {
      controls.start({ opacity: 0, y: 50 }); // Вернуть в начальное состояние при скрытии
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
      style={{ position: "absolute", ...props }}
    >
      {children}
    </motion.div>
  );
};

export default FadeInFromBottom;
