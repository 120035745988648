import React, { useState } from "react";
import {
  Button,
  Box,
  Text,
  VStack,
  HStack,
  Stack,
  Image,
} from "@chakra-ui/react";
import useWindowDimensions from "./windowDimensions";
import { keyframes } from "@emotion/react";
import FadeInWrapper from "./fadein";
import face_rec from "./../images/3.gif";
import { useEffect } from "react";
import "./voice.css";
import { useToast } from "@chakra-ui/react";
import anyhelp from "./../images/anyhelp.mp4";

const VoiceAssistant = ({ sended }) => {
  const [response, setResponse] = useState("Спросите что нибудь");
  const [isListening, setIsListening] = useState(false);
  const [started, setStarted] = useState(false);

  const handleVoiceInput = async () => {
    setResponse("Запись...");
    setIsListening(true);
    setStarted(true);

    try {
      // Запрашиваем доступ к микрофону
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      // Собираем аудио данные
      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      mediaRecorder.start();

      setTimeout(() => {
        mediaRecorder.stop();
      }, 10000); // Записываем аудио 5 секунд

      mediaRecorder.addEventListener("stop", async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });

        // Проверяем, записан ли аудиофайл
        console.log("Длина аудиофайла:", audioBlob.size);

        if (audioBlob.size === 0) {
          setResponse("Ошибка записи аудио. Попробуйте снова.");
          setIsListening(false);
          return;
        }

        // Создаем форму для отправки аудиофайла
        const formData = new FormData();
        formData.append("file", audioBlob, "audio.wav");
        formData.append("model", "whisper-1");

        try {
          // Отправляем аудио на Whisper API для распознавания речи
          const whisperResponse = await fetch(
            "http://81.22.132.99:8000/whisper",
            {
              method: "POST",
              headers: {
                accept: "application/json",
                // "Content-Type": "multipart/form-data",
              },
              body: formData,
            }
          );

          const whisperData = await whisperResponse.json();

          // Обрабатываем ошибки API
          if (whisperData.error) {
            throw new Error(whisperData.error.message);
          }

          const userQuestion = whisperData.transcription;

          if (!userQuestion) {
            setResponse("Не удалось распознать речь. Попробуйте снова.");
            setIsListening(false);
            return;
          }

          setResponse(`Вы спросили: ${userQuestion}`);
          console.log(userQuestion);

          // Отправляем вопрос на ChatGPT для получения ответа
          const chatGptResponse = await fetch("http://81.22.132.99:8000/gpt", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            body: JSON.stringify({
              question: userQuestion,
            }),
          });

          const chatGptData = await chatGptResponse.json();

          // Обрабатываем ошибки API ChatGPT
          if (chatGptData.error) {
            throw new Error(chatGptData.error.message);
          }

          const chatGptAnswer = chatGptData.answer;
          setResponse(`Ответ: ${chatGptAnswer}`);
          setIsListening(false);
        } catch (error) {
          setResponse("Ошибка: " + error.message);
          setIsListening(false);
        }
      });
    } catch (error) {
      setResponse("Ошибка при записи аудио: " + error.message);
      setIsListening(false);
    }
  };
  const { width, height } = useWindowDimensions();
  const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
`;
  const rainbowGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;
  const toast = useToast();

  return (
    // <VStack spacing={4} align="center" justify="center" h="100vh" bg="gray.50">
    //   <Box textAlign="center">
    //     <Text fontSize="2xl" mb={4}>
    //       Спросите нашего голосового помощника о нас
    //     </Text>
    //     <Button
    //       colorScheme="teal"
    //       size="lg"
    //       onClick={handleVoiceInput}
    //       isLoading={isListening}
    //     >
    //       Нажмите и задайте вопрос
    //     </Button>
    //     <Box mt={6}>
    //       <Text fontSize="lg" color="gray.600">
    //         {response}
    //       </Text>
    //     </Box>
    //   </Box>
    // </VStack>
    <Stack
      padding={
        width >= 1440
          ? "70px"
          : ["20px", "30px", "40px", "50px", "60px", "70px"]
      }
      flexDirection={width >= 971 ? "row" : "column"}
      gap={
        width >= 1440
          ? "70px"
          : ["20px", "30px", "40px", "50px", "60px", "70px"]
      }
      justifyContent={"space-between"}
    >
      <VStack align={"flex-start"} position={"relative"} width={"470px"}>
        <FadeInWrapper>
          <Text
            color={"white"}
            fontSize={
              width >= 1440 ? "44px" : ["28px", "32px", "35px", "37px", "40px"]
            }
            fontWeight={600}
            fontFamily={"Montserrat"}
            lineHeight={"normal"}
            bgGradient="linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
            bgClip="text"
            backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
            animation={`${rainbowGradient} 5s ease infinite`} // Применяем анимацию
          >
            ИИ ассистент ipanel
          </Text>
        </FadeInWrapper>
        <FadeInWrapper slow={true}>
          <Text
            color={"white"}
            fontSize={width > 1440 ? "16px" : ["14px", "15px", "16px"]}
            fontWeight={600}
            fontFamily={"Montserrat"}
            marginTop={"20px"}
          >
            Ответит на любые ваши вопросы! Попробуйте спросить его о нас
          </Text>
        </FadeInWrapper>
        {width >= 971 ? (
          <FadeInWrapper slow={true}>
            <Text
              marginTop={
                width >= 1440
                  ? "70px"
                  : ["20px", "30px", "40px", "50px", "60px", "70px"]
              }
              color={"rgba(3, 48, 67, 1)"}
              fontSize={width > 1440 ? "16px" : ["14px", "15px", "16px"]}
              fontWeight={600}
              backgroundColor={"rgba(93, 191, 196, 1)"}
              padding={"10px 20px"}
              width={"max-content"}
              borderRadius={"10px"}
              cursor={"pointer"}
              transition={"0.5s"}
              fontFamily="Montserrat"
              zIndex={5}
              bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
              bgSize="200% 200%"
              animation={`${gradientAnimation} 8s ease infinite`}
              _hover={{
                backgroundColor: "transparent",
                bgGradient:
                  "linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))",
                bgClip: "text",
                color: "rgba(93, 191, 196, 1)",
                transition: "0.5s",
              }}
              // onClick={() => scrollToSection(section2Ref)}
              onClick={() => {
                if (sended) {
                  handleVoiceInput();
                } else {
                  toast({
                    render: () => (
                      <VStack
                        borderRadius={"32px"}
                        padding={"16px"}
                        border={"1px solid rgba(93, 191, 196, 1)"}
                        backgroundColor={"rgba(28, 28, 28, 1)"}
                      >
                        <Text color={"white"} fontFamily={"Rubik"}>
                          Тестирование ИИ ассистента доступно только
                          пользователям отправившим обращение
                        </Text>
                      </VStack>
                    ),
                    duration: 5000,
                    isClosable: true,
                  });
                }
              }}
              isLoading={isListening}
            >
              Попробовать
            </Text>
          </FadeInWrapper>
        ) : null}
      </VStack>
      <VStack width={width >= 971 ? "65%" : "100%"}>
        {started ? (
          <VStack
            width={width >= 540 ? "508px" : "90%"}
            borderRadius={"32px"}
            height={width >= 540 ? "246px" : "20vh"}
            backgroundColor={"1E1E1E"}
            border={"2px solid gray"}
            align={"center"}
            justify={"center"}
            overflowX={"hidden"}
            padding={"16px"}
            boxShadow={"0px 0px 20px 12px rgba(93, 191, 196, 0.1)"}
            alignSelf={width >= 971 ? "flex-end" : "center"}
          >
            {/* <Image
            src={face_rec}
            width={"200px"}
            height={"200px"}
            borderRadius={"100px"}
            objectFit={'cover'}
            border={"2px solid gray"}
            
          /> */}
            <VStack overflowY={"scroll"}>
              <div className="typing-container">
                {response.split("").map((char, index) => (
                  <span
                    key={index}
                    className="typing-char"
                    style={{
                      animationDelay: `${index * 0.05}s`,
                      color: "white",
                      fontFamily: "Rubik",
                    }} // Устанавливаем задержку
                  >
                    {char}
                  </span>
                ))}
              </div>
            </VStack>
          </VStack>
        ) : (
          <VStack alignSelf={"flex-end"}>
            <video
              src={anyhelp}
              width="640"
              height={width >= 540 ? "246px" : "20vh"}
              style={{
                maxWidth: "508px",
                borderRadius: "32px",
                border: "2px solid gray",
                boxShadow: "0px 0px 20px 12px rgba(93, 191, 196, 0.1)",
              }}
              autoPlay
              muted
              loop
            />
          </VStack>
        )}
        {width >= 971 ? null : (
          <FadeInWrapper slow={true}>
            <Text
              marginTop={
                width >= 1440
                  ? "70px"
                  : ["20px", "30px", "40px", "50px", "60px", "70px"]
              }
              color={"rgba(3, 48, 67, 1)"}
              fontSize={width > 1440 ? "16px" : ["14px", "15px", "16px"]}
              fontWeight={600}
              backgroundColor={"rgba(93, 191, 196, 1)"}
              padding={"10px 20px"}
              width={"max-content"}
              borderRadius={"10px"}
              cursor={"pointer"}
              transition={"0.5s"}
              fontFamily="Montserrat"
              zIndex={5}
              bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
              bgSize="200% 200%"
              animation={`${gradientAnimation} 8s ease infinite`}
              _hover={{
                backgroundColor: "transparent",
                bgGradient:
                  "linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))",
                bgClip: "text",
                color: "rgba(93, 191, 196, 1)",
                transition: "0.5s",
              }}
              // onClick={() => scrollToSection(section2Ref)}
              onClick={handleVoiceInput}
              isLoading={isListening}
            >
              Попробовать
            </Text>
          </FadeInWrapper>
        )}
      </VStack>
      {/* <HStack>
        <Image src={planet} />
      </HStack> */}
      {width >= 1440 ? (
        <VStack alignItems={"center"} gap={"20px"}>
          <Stack bgColor={"white"} height={"100%"} width={"1px"} />
        </VStack>
      ) : null}
    </Stack>
  );
};

export default VoiceAssistant;
