import React, { useRef, useState, useEffect } from "react";
import "./FadeInWrapper.css";

const FadeInWrapper = ({ slow = false, widget = false, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <>
      {widget ? (
        <div
          ref={ref}
          className={
            slow
              ? `fade-in-wrapper-slow ${isVisible ? "visible" : ""}`
              : `fade-in-wrapper ${isVisible ? "visible" : ""}`
          }
          style={{ position: "inherit", bottom: "inherit", right: "inherit" }}
        >
          {children}
        </div>
      ) : (
        <div
          ref={ref}
          className={
            slow
              ? `fade-in-wrapper-slow ${isVisible ? "visible" : ""}`
              : `fade-in-wrapper ${isVisible ? "visible" : ""}`
          }
        >
          {children}
        </div>
      )}
    </>
  );
};

export default FadeInWrapper;
