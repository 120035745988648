import {
  HStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import verticalMonitor from "../images/vert_monitor.svg";
import horizontalMonitor from "../images/hor_monitor.svg";
import check from "../images/check.svg";
import check_active from "../images/check_active.svg";
import { useState } from "react";
import useWindowDimensions from "./windowDimensions";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { Autoplay, FreeMode, Navigation } from "swiper/modules";

import imageLift1 from "../images/imagelift1.png";
// import imageLift2 from "../../images/imagelift2.png";

const Monitor = ({
  model,
  ratio = "16:9",
  size = "28,6",
  panel_size = "340x1200",
  resolution = "540:1920",
  price = "195 000 - 225 00",
  orientation = "Вертикальная/горизонтальная"
}) => {
  const [selected, setSelected] = useState([[false], [0]]);
  const { width } = useWindowDimensions();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const copy_selected = Array.from(selected);
  return (
    <VStack align={"flex-start"}>
      <HStack
        alignItems={"end"}
        gap={"10px"}
        onClick={onOpen}
        cursor={"pointer"}
      >
        <Image src={verticalMonitor} />
        <Image src={horizontalMonitor} />
      </HStack>
      <VStack
        marginTop={"10px"}
        gap={"10px"}
        align={"flex-start"}
        onClick={onOpen}
        cursor={"pointer"}
      >
        <Text
          color={"white"}
          fontFamily={"Montserrat"}
          fontSize={
            width >= 1440 ? "25px" : ["16px", "18px", "19px", "20px", "21px"]
          }
          fontWeight={"600"}
        >
          {model}
        </Text>
        <HStack
          gap={"10px"}
          fontFamily={"Montserrat"}
          fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
          fontWeight={"500"}
        >
          <Text color={"rgba(153, 153, 153, 1)"}>Соотношение сторон</Text>
          <Text color={"white"}>{ratio}</Text>
        </HStack>
        <HStack
          gap={"10px"}
          fontFamily={"Montserrat"}
          fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
          fontWeight={"500"}
        >
          <Text color={"rgba(153, 153, 153, 1)"}>Размер панели</Text>
          <Text color={"white"}>{panel_size}</Text>
        </HStack>
        <HStack
          gap={"10px"}
          fontFamily={"Montserrat"}
          fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
          fontWeight={"500"}
        >
          <Text color={"rgba(153, 153, 153, 1)"}>Разрешение экрана</Text>
          <Text color={"white"}>{resolution}</Text>
        </HStack>
        <HStack
          gap={"10px"}
          fontFamily={"Montserrat"}
          fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
          fontWeight={"500"}
        >
          <Text color={"rgba(153, 153, 153, 1)"}>Размер матрицы</Text>
          <Text color={"white"}>{size}</Text>
        </HStack>
        <HStack
          gap={"10px"}
          fontFamily={"Montserrat"}
          fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
          fontWeight={"500"}
        >
          <Text color={"white"}>{orientation}</Text>
        </HStack>
      </VStack>
      <VStack>
        <HStack
          align={"center"}
          justify={"center"}
          marginTop={width >= 1440 ? "40px" : "10px"}
          gap={"20px"}
        >
          <Image
            width={width >= 1440 ? "36px" : ["32px", "35px"]}
            src={selected[0][0] ? check_active : check}
            border={
              selected[0][0]
                ? "1px solid rgba(93, 191, 196, 1)"
                : "1px solid rgba(153, 153, 153, 1)"
            }
            padding={
              width >= 1440
                ? "13px"
                : ["8px", "9px", "10px", "11px", "12px", "13px"]
            }
            borderRadius={"10px"}
            backgroundColor={selected[0][0] ? "rgba(93, 191, 196, 1)" : null}
            onClick={() => {
              copy_selected[0][0] = !copy_selected[0][0];
              setSelected(copy_selected);
            }}
            cursor={"pointer"}
          />
          <Text
            fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
            fontWeight={"500"}
            fontFamily={"Montserrat"}
            color={
              copy_selected[0][0]
                ? "rgba(93, 191, 196, 1)"
                : "rgba(153, 153, 153, 1)"
            }
          >
            {price} руб
          </Text>
        </HStack>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
        <ModalOverlay backdropFilter={"blur(10px)"} />
        <ModalContent
          backgroundColor={"rgba(40,40,40,1)"}
          padding={"40px"}
          borderRadius={"15px"}
          margin={"auto 40px"}
          width={"100%"}
        >
          <ModalHeader>
            <ModalCloseButton color={"white"} />
          </ModalHeader>
          <HStack
            justifyContent={"space-between"}
            align={"center"}
            width={"100%"}
          >
            <VStack align={"flex-start"} width={"80%"}>
              <HStack
                alignItems={"end"}
                gap={"20px"}
                onClick={onOpen}
                cursor={"pointer"}
              >
                <Image src={verticalMonitor} />
                <Image src={horizontalMonitor} />
              </HStack>
              <VStack
                marginTop={"20px"}
                gap={"10px"}
                align={"flex-start"}
                onClick={onOpen}
                cursor={"pointer"}
              >
                <Text
                  color={"white"}
                  fontFamily={"Montserrat"}
                  fontSize={
                    width >= 1440
                      ? "30px"
                      : ["18px", "20px", "21px", "22px", "25px"]
                  }
                  fontWeight={"600"}
                >
                  {model}
                </Text>
                <HStack
                  gap={"10px"}
                  fontFamily={"Montserrat"}
                  fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                  fontWeight={"500"}
                >
                  <Text color={"rgba(153, 153, 153, 1)"}>
                    Соотношение сторон
                  </Text>
                  <Text color={"white"}>{ratio}</Text>
                </HStack>
                <HStack
                  gap={"10px"}
                  fontFamily={"Montserrat"}
                  fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                  fontWeight={"500"}
                >
                  <Text color={"rgba(153, 153, 153, 1)"}>Размер панели</Text>
                  <Text color={"white"}>{panel_size}</Text>
                </HStack>
                <HStack
                  gap={"10px"}
                  fontFamily={"Montserrat"}
                  fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                  fontWeight={"500"}
                >
                  <Text color={"rgba(153, 153, 153, 1)"}>
                    Разрешение экрана
                  </Text>
                  <Text color={"white"}>{resolution}</Text>
                </HStack>
                <HStack
                  gap={"10px"}
                  fontFamily={"Montserrat"}
                  fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                  fontWeight={"500"}
                >
                  <Text color={"rgba(153, 153, 153, 1)"}>Размер матрицы</Text>
                  <Text color={"white"}>{size}</Text>
                </HStack>
                <HStack
                  gap={"10px"}
                  fontFamily={"Montserrat"}
                  fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                  fontWeight={"500"}
                >
                  <Text color={"white"}>Вертикальная/горизонтальная</Text>
                </HStack>
              </VStack>
            </VStack>
            <Swiper
              style={{
                display: "flex",
                position: "relative",
                "--swiper-navigation-color": "#fff",
              }}
              modules={[FreeMode, Navigation]}
              freeMode={false}
              navigation
              // spaceBetween={40}
            >
              <SwiperSlide
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Image src={imageLift1} w={"300px"} height={"300px"} />
              </SwiperSlide>
              <SwiperSlide
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Image src={imageLift1} w={"300px"} height={"300px"} />
              </SwiperSlide>
            </Swiper>
          </HStack>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Monitor;
