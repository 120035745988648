import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Image, Spinner, Text, VStack } from "@chakra-ui/react";
import useWindowDimensions from "./windowDimensions";
import face_rec from "./../images/3.gif";
const WebcamComponent = () => {
  const videoRef = useRef(null); // Реф для видео-элемента
  const [loading, setLoading] = useState(false); // Состояние загрузки
  const [error, setError] = useState(""); // Состояние для ошибок
  const [detections, setDetections] = useState([]); // Состояние для результатов
  const { width, height } = useWindowDimensions();

  // Функция для захвата видеопотока с камеры
  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStarted(true);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      setError("Ошибка при доступе к камере.");
      setStarted(false);
      console.error("Ошибка при доступе к камере: ", err);
    }
  };

  // Функция для отправки кадра на сервер
  const sendFrameToServer = async (frameBlob) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", frameBlob, "frame.jpg");

      const response = await fetch("http://212.41.9.251:8888/process_frame/", {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      setDetections(result.detections); // Сохранение результатов детекции
    } catch (err) {
      setError("Ошибка при отправке данных на сервер.");
      console.error("Ошибка при отправке данных: ", err);
    }
    setLoading(false);
  };

  // Отправка кадров каждые 2 секунды
  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current) {
        const canvas = document.createElement("canvas");
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        detections.forEach((detection) => {
          const { face_box, gender, age } = detection;

          const [x1, y1, x2, y2] = [
            face_box[0] * canvas.width,
            face_box[1] * canvas.height,
            face_box[2] * canvas.width,
            face_box[3] * canvas.height,
          ];

          // Рисуем прямоугольник
          ctx.strokeStyle = "green";
          ctx.lineWidth = 2;
          ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);

          // Рисуем текст над прямоугольником
          ctx.fillStyle = "blue";
          ctx.font = "12px Arial";
          ctx.fillText(`Пол: ${gender}, Возраст: ${age}`, x1, y1 - 10);
        });

        canvas.toBlob((blob) => {
          if (blob) {
            sendFrameToServer(blob); // Отправка кадра на сервер
          }
        }, "image/jpeg");
      }
    }, 2000);

    return () => clearInterval(interval); // Очищаем интервал при размонтировании
  }, []);
  const [started, setStarted] = useState(false);

  //   useEffect(() => {
  //     startWebcam(); // Запуск камеры при монтировании компонента
  //   }, []);

  return (
    <VStack
      textAlign="center"
      position={"relative"}
      alignSelf={"flex-end"}
      fontFamily={"Montserrat"}
      onClick={startWebcam}
      cursor={"pointer"}
    >
      <Box mb={4}>
        {started ? (
          <video
            ref={videoRef}
            width="640"
            height="480"
            poster=""
            style={{
              maxWidth: "508px",
              borderRadius: "32px",
              border: "2px solid gray",
              boxShadow: "0px 0px 20px 12px rgba(93, 191, 196, 0.1)",
            }}
            autoPlay
          />
        ) : (
          <VStack
            width={width >= 540 ? "508px" : "90%"}
            borderRadius={"32px"}
            height={width >= 540 ? "246px" : "20vh"}
            backgroundColor={"1E1E1E"}
            border={"2px solid gray"}
            align={"center"}
            justify={"center"}
            boxShadow={"0px 0px 20px 12px rgba(93, 191, 196, 0.1)"}
          >
            <Image
              src={face_rec}
              width={"200px"}
              height={"200px"}
              borderRadius={"100px"}
              objectFit={"cover"}
              border="2px solid gray"
            />
            <Text color={"white"} fontFamily={"Rubik"}>
              Нажмите на меня дважды чтобы протестировать!
            </Text>
          </VStack>
        )}
      </Box>

      {/* {loading && <Spinner size="xl" color="blue.500" />}

      {error && <Text color="red.500">{error}</Text>} */}

      {detections.length > 0 && (
        <VStack
          mt={4}
          position={"absolute"}
          top={5}
          left={10}
          color={"lime"}
          align={"flex-start"}
        >
          <Text
            fontSize="lg"
            fontWeight="bold"
            color={"rgba(93, 191, 196, 1)"}
            fontFamily={"Rubik"}
          >
            Результаты распознавания:
          </Text>
          {detections.map((detection, index) => (
            <Text
              key={index}
              color={"rgba(93, 191, 196, 1)"}
              fontFamily={"Rubik"}
            >
              {`Пол: ${
                detection.gender == "male" ? "мужчина" : "женщина"
              }, Возраст: ${detection.age}`}
            </Text>
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default WebcamComponent;
