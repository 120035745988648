import {
  Fade,
  HStack,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
  Form,
  Box,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { keyframes } from "@emotion/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import emailjs from "emailjs-com";
import { useBreakpointValue } from "@chakra-ui/react";

import Header from "../components/header";
import Monitor from "../components/monitor";
import "swiper/css";
import useWindowDimensions from "../components/windowDimensions";
import WebcamComponent from "../components/webcam_component";

import back from "../images/back.png";
import theme from "../images/theme.svg";
import lift from "../images/image_lift.png";
import blur from "../images/blur.svg";
import imageLift1 from "../images/imagelift1.png";
import imageLift2 from "../images/imagelift2.png";
import toggleIcon from "../images/Toggles.svg";
import cpu from "../images/CpuFill.svg";
import motherBoard from "../images/MotherboardFill.svg";
import elevator from "../images/elevator.svg";
import liftBrand from "../images/logo.svg";
import iPanel from "../images/ipanel.svg";
import all_themes from "../images/all_themes.png";
import imageVideo from "../images/video_image.svg";
import minusIcon from "../images/minus.svg";
import plusIcon from "../images/plus.svg";
import slide4Lift from "../images/page4lift.svg";
import planet from "../images/planet.svg";
import VoiceAssistant from "../components/voice_assistant";
import AudioRecorder from "../components/audio_recorder";
import date1 from "../images/Date-1.svg";
import date2 from "../images/Date-2.svg";
import date3 from "../images/Date.svg";
import date4 from "../images/Date-3.svg";
import weather1 from "../images/Weather.svg";
import weather2 from "../images/Weather-1.svg";
import time from "../images/Time.svg";
import usdeur1 from "../images/USD EUR.svg";
import usdeur2 from "../images/USD EUR-2.svg";
import clock from "../images/Clock.svg";
import render1 from "../images/image8.png";
import render2 from "../images/image10.png";
import render3 from "../images/image11.png";
import render4 from "../images/image13.png";
import render5 from "../images/image14.png";
import render6 from "../images/image15.png";
import FadeInWrapper from "../components/fadein";

import question from "./../images/question.png";
import FadeInFromBottom from "../components/fade_in_from_bottom";
import LazyVideo from "../components/lazy_video";
import video51 from "./../images/IMG_5219.MP4";
import video52 from "./../images/IMG_3456.mp4";
import Carousel from "../components/iphone";
import scheme from "./../images/scheme.png";

const MainPage = () => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

  const scrollToSection = (ref) => {
    const targetPosition =
      ref.current.getBoundingClientRect().top + window.pageYOffset;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    const duration = 1000; // Продолжительность прокрутки в миллисекундах
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

  const fadeIn = keyframes`0%, 20% {opacity: 0}, 50%, 100% {opacity: 1}`;
  const fade = `${fadeIn} 2s forwards`;

  const [selected, setSelected] = useState([[0], [0, 0]]);
  const copy_selected = Array.from(selected);

  const { width, height } = useWindowDimensions();
  const [sended, setSended] = useState(false);
  const [message_form, setMessageForm] = useState({ email: "", message: "" });
  const toast = useToast();
  const sendEmail = (formValues) => {
    const email = formValues?.email; // Данные для отправки
    const message = `${formValues?.message} количество панелей ${copy_selected[0][0]}`;

    // Заполните эти данные из вашей учетной записи EmailJS
    const serviceID = "service_n8hefjw";
    const templateID = "template_3ndk5sf";
    const publicKey = "eyd4c_S1N_gHy3oZv";

    return emailjs
      .send(serviceID, templateID, { email, message }, publicKey)
      .then((response) => {
        console.log("Email успешно отправлен!", response.status, response.text);
        toast({
          render: () => (
            <VStack
              borderRadius={"32px"}
              padding={"16px"}
              border={"1px solid rgba(93, 191, 196, 1)"}
              backgroundColor={"rgba(28, 28, 28, 1)"}
            >
              <Text color={"white"} fontFamily={"Rubik"}>
                Ваше обращение отправлено!
              </Text>
            </VStack>
          ),
          duration: 5000,
          isClosable: true,
        });
        setSended(true);
        return { success: true };
      })
      .catch((error) => {
        console.error("Ошибка при отправке email:", error);
        toast({
          render: () => (
            <VStack
              borderRadius={"32px"}
              padding={"16px"}
              border={"1px solid rgba(93, 191, 196, 1)"}
              backgroundColor={"rgba(28, 28, 28, 1)"}
            >
              <Text color={"white"} fontFamily={"Rubik"}>
                Ошибка при отправке!
              </Text>
            </VStack>
          ),
          duration: 5000,
          isClosable: true,
        });
        setSended(false);
        return { success: false, error };
      });
  };
  const fontSize = useBreakpointValue({
    base: "18px",
    sm: "21px",
    md: "24px",
    lg: "27px",
    xl: "30px",
  });
  const marginTop = useBreakpointValue({
    base: "20px",
    sm: "30px",
    md: "40px",
    lg: "50px",
    xl: "60px",
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    // Получаем данные из формы
    const formData = new FormData(event.target);
    const formValues = {
      message: formData.get("message"),
      email: formData.get("email"),
    };
    console.log(formValues);

    sendEmail(formValues); // Ваша функция отправки email
  };
  return (
    <VStack
      backgroundColor={"rgba(28, 28, 28, 1)"}
      width={"100%"}
      paddingX={width >= 720 ? "40px" : null}
    >
      <Stack
        width={"100%"}
        backgroundColor={"rgba(28, 28, 28, 1)"}
        gap={0}
        margin={0}
        padding={width > 1660 ? `0 140px` : null}
        overflowX={"hidden"}
      >
        <Header />
        <Stack position={"relative"} backgroundColor={"black"}>
          {width >= 1440 ? (
            <Image
              src={back}
              position={"absolute"}
              top={0}
              left={0}
              width={"100%"}
              maxHeight={"601px"}
              objectFit={"contain"}
            />
          ) : null}

          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            padding={
              width >= 1440
                ? "0 55px 0 70px"
                : [
                    "0 15px 0 20px",
                    "0 20px 0 30px",
                    "0 25px 0 40px",
                    "0 35px 0 50px",
                    "0 45px 0 60px",
                    "0 55px 0 70px",
                  ]
            }
          >
            <Stack flexDirection={"column"} zIndex={2} position={"relative"}>
              <Image src={blur} position={"absolute"} zIndex={1} />
              <FadeInWrapper>
                <VStack spacing={0} align={"flex-start"}>
                  <HStack align={"center"}>
                    <Text
                      color={"white"}
                      fontSize={
                        width >= 1440
                          ? "60px"
                          : ["30px", "40px", "50px", "60px", "60px"]
                      }
                      fontWeight={600}
                      marginTop={
                        width >= 1440
                          ? "84px"
                          : [
                              "24px",
                              "34px",
                              "44px",
                              "54px",
                              "64px",
                              "74px",
                              "84px",
                            ]
                      }
                      lineHeight={width >= 1440 ? "80px" : null}
                      fontFamily="Montserrat"
                      bgGradient="linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                      bgClip="text"
                      backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                      animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
                      //
                    >
                      I-PANEL
                    </Text>
                    <Text
                      marginTop={
                        width >= 1440
                          ? "84px"
                          : [
                              "24px",
                              "34px",
                              "44px",
                              "54px",
                              "64px",
                              "74px",
                              "84px",
                            ]
                      }
                      fontFamily="Montserrat"
                      bgGradient="linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                      bgClip="text"
                      backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                      animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
                      color={"white"}
                      fontSize={
                        width >= 1440
                          ? "45px"
                          : ["15px", "25px", "35px", "45px", "45px"]
                      }
                    >
                      {" "}
                      - Ваш центр внимания
                    </Text>
                  </HStack>

                  <Text fontSize={"14px"} color={"white"}>
                    Технологии в каждом пикселе
                  </Text>
                </VStack>
              </FadeInWrapper>
              <FadeInWrapper slow={true}>
                <Text
                  fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                  color={"white"}
                  fontWeight={500}
                  marginTop={
                    width >= 1440 ? "45px" : ["15px", "25px", "35px", "45px"]
                  }
                  fontFamily="Montserrat"
                >
                  {width >= 1440 ? (
                    <Text>
                      Идеальное решение для отелей, бизнес-центров,
                      <br />
                      торговых комплексов, аэропортов, лифтовых холлов и других
                      <br />
                      общественных пространств.
                    </Text>
                  ) : (
                    `Рекламно-информационные экраны для лифтовых холлов и лифтов
              под заказ.`
                  )}
                </Text>
              </FadeInWrapper>

              <Text
                marginTop={
                  width >= 1440 ? "50px" : ["20px", "30px", "40px", "50px"]
                }
                color={"rgba(3, 48, 67, 1)"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={600}
                padding={"10px 20px"}
                width={"max-content"}
                borderRadius={"10px"}
                cursor={"pointer"}
                transition={"0.5s"}
                fontFamily="Montserrat"
                zIndex={5}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgSize="200% 200%"
                animation={`${gradientAnimation} 8s ease infinite`}
                _hover={{
                  backgroundColor: "transparent",
                  bgGradient:
                    "linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))",
                  bgClip: "text",
                  color: "rgba(93, 191, 196, 1)",
                  transition: "0.5s",
                }}
                onClick={() => scrollToSection(section2Ref)}
              >
                Подробнее
              </Text>
            </Stack>
            {width >= 1440 ? (
              <Stack
                flexDirection={"column"}
                gap={"20px"}
                zIndex={2}
                alignItems={"center"}
              >
                {/* <Stack bgColor={"white"} height={"126px"} width={"1px"} /> */}
                <VStack gap={"10px"} marginTop={"146px"}>
                  <Stack
                    width={"40px"}
                    height={"40px"}
                    borderRadius={"100"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderColor={"rgba(255,255,255,1))"}
                    color={"rgba(255,255,255,1)"}
                    borderWidth={"1px"}
                    cursor={"pointer"}
                    _hover={{
                      borderColor: "rgba(93, 191, 196, 1)",
                      color: "rgba(93, 191, 196, 1)",
                    }}
                    onClick={() => scrollToSection(section1Ref)}
                  >
                    <Text
                      fontSize={
                        width > 1440 ? "14px" : ["14px", "14px", "14px"]
                      }
                      fontFamily={"Montserrat"}
                    >
                      1
                    </Text>
                  </Stack>
                  <Stack
                    width={"40px"}
                    height={"40px"}
                    borderRadius={"100"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderColor={"rgba(255,255,255,1)"}
                    color={"rgba(255,255,255,1)"}
                    borderWidth={"1px"}
                    cursor={"pointer"}
                    _hover={{
                      borderColor: "rgba(93, 191, 196, 1)",
                      color: "rgba(93, 191, 196, 1)",
                    }}
                    onClick={() => scrollToSection(section2Ref)}
                  >
                    <Text
                      fontSize={
                        width > 1440 ? "14px" : ["14px", "14px", "14px"]
                      }
                      fontFamily={"Montserrat"}
                    >
                      2
                    </Text>
                  </Stack>
                  <Stack
                    width={"40px"}
                    height={"40px"}
                    borderRadius={"100"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderColor={"rgba(255,255,255,1)"}
                    color={"rgba(255,255,255,1)"}
                    borderWidth={"1px"}
                    cursor={"pointer"}
                    _hover={{
                      borderColor: "rgba(93, 191, 196, 1)",
                      color: "rgba(93, 191, 196, 1)",
                    }}
                    onClick={() => scrollToSection(section3Ref)}
                  >
                    <Text
                      fontSize={
                        width > 1440 ? "14px" : ["14px", "14px", "14px"]
                      }
                      fontFamily={"Montserrat"}
                    >
                      3
                    </Text>
                  </Stack>
                  <Stack
                    width={"40px"}
                    height={"40px"}
                    borderRadius={"100"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderColor={"rgba(255,255,255,1)"}
                    color={"rgba(255,255,255,1)"}
                    borderWidth={"1px"}
                    cursor={"pointer"}
                    _hover={{
                      borderColor: "rgba(93, 191, 196, 1)",
                      color: "rgba(93, 191, 196, 1)",
                    }}
                    onClick={() => scrollToSection(section4Ref)}
                  >
                    <Text
                      fontSize={
                        width > 1440 ? "14px" : ["14px", "14px", "14px"]
                      }
                      fontFamily={"Montserrat"}
                    >
                      4
                    </Text>
                  </Stack>
                </VStack>
                <Stack bgColor={"white"} height={"175px"} width={"1px"} />
              </Stack>
            ) : null}
          </Stack>
          <Stack
            bgColor={"white"}
            height={"1px"}
            width={"100%"}
            zIndex={2}
            marginTop={"60px"}
          />
        </Stack>
        {width >= 1440 ? (
          <Stack
            width={"100%"}
            flexDirection={"row"}
            padding={
              width >= 1440
                ? "70px"
                : ["20px", "30px", "40px", "50px", "60px", "70px"]
            }
            justifyContent={"space-between"}
          >
            <VStack width={"100%"} position={"relative"}>
              <FadeInWrapper>
                <Text
                  fontSize={
                    width >= 1440
                      ? "50px"
                      : ["34px", "38px", "42px", "46px", "50px"]
                  }
                  fontWeight={600}
                  color={"white"}
                  lineHeight={width >= 1440 ? "80px" : null}
                  fontFamily={"Montserrat"}
                  bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                  bgClip="text"
                  backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                  animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
                >
                  Уникальные особенности I-PANEL
                </Text>
              </FadeInWrapper>

              {/*               <Image src={question} objectFit={"cover"} /> */}
              <VStack
                pos={"relative"}
                backgroundImage={question}
                backgroundSize={"cover"}
                width={"100%"}
                height={"500px"}
                backgroundPosition={"center"}
              >
                <Tooltip
                  label="Промышленная вытянутая матрица: яркость в 2 раза выше (700 нит), ресурсом 50 000 часов и режимом 24/7"
                  placement="bottom"
                  bg="rgba(28, 28, 28, 1)"
                  color="white"
                  openDelay={300}
                  closeDelay={200}
                  fontSize={16}
                  fontFamily={"Montserrat"}
                >
                  <VStack
                    position={"absolute"}
                    top={"9vh"}
                    left={"15vw"}
                    padding={"5px 10px"}
                    borderRadius={"12px"}
                  >
                    <Text
                      fontWeight={600}
                      color={"white"}
                      fontSize={16}
                      fontFamily={"Montserrat"}
                      bgGradient="linear(to-r, rgba(0, 200, 220, 1), rgba(0, 220, 255, 1), rgba(102, 245, 255, 1), rgba(180, 250, 255, 1))"
                      bgClip="text"
                      backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                      animation={`${gradientAnimation} 5s ease infinite`}
                      cursor={"help"}
                    >
                      Надежность
                    </Text>
                  </VStack>
                </Tooltip>
                <Tooltip
                  label="Модульная конструкция и лёгкий монтаж на стену в накладном корпусе."
                  placement="bottom"
                  bg="rgba(28, 28, 28, 1)"
                  color="white"
                  openDelay={300}
                  closeDelay={200}
                  fontSize={16}
                  fontFamily={"Montserrat"}
                >
                  <VStack
                    position={"absolute"}
                    top={"10vh"}
                    left={"40vw"}
                    padding={"5px 10px"}
                    borderRadius={"12px"}
                  >
                    <Text
                      fontWeight={600}
                      color={"white"}
                      fontSize={16}
                      fontFamily={"Montserrat"}
                      bgGradient="linear(to-r, rgba(0, 200, 220, 1), rgba(0, 220, 255, 1), rgba(102, 245, 255, 1), rgba(180, 250, 255, 1))"
                      bgClip="text"
                      backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                      animation={`${gradientAnimation} 5s ease infinite`}
                      cursor={"help"}
                    >
                      Удобство использования
                    </Text>
                  </VStack>
                </Tooltip>
                <Tooltip
                  label="Инновационный ИИ ассистент для мгновенной обратной связи."
                  placement="bottom"
                  bg="rgba(28, 28, 28, 1)"
                  color="white"
                  openDelay={300}
                  closeDelay={200}
                  fontSize={16}
                  fontFamily={"Montserrat"}
                >
                  <VStack
                    position={"absolute"}
                    top={"15vh"}
                    left={"61vw"}
                    padding={"5px 10px"}
                    borderRadius={"12px"}
                  >
                    <Text
                      fontWeight={600}
                      color={"white"}
                      fontSize={16}
                      fontFamily={"Montserrat"}
                      bgGradient="linear(to-r, rgba(0, 200, 220, 1), rgba(0, 220, 255, 1), rgba(102, 245, 255, 1), rgba(180, 250, 255, 1))"
                      bgClip="text"
                      backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                      animation={`${gradientAnimation} 5s ease infinite`}
                      cursor={"help"}
                    >
                      Эффективность
                    </Text>
                  </VStack>
                </Tooltip>
                <Tooltip
                  label="Тонкий и элегантный дизайн в алюминиевом корпусе, 5 вариантов цвета."
                  placement="bottom"
                  bg="rgba(28, 28, 28, 1)"
                  color="white"
                  openDelay={300}
                  closeDelay={200}
                  fontSize={16}
                  fontFamily={"Montserrat"}
                >
                  <VStack
                    position={"absolute"}
                    top={"35vh"}
                    left={"62vw"}
                    padding={"5px 10px"}
                    borderRadius={"12px"}
                  >
                    <Text
                      fontWeight={600}
                      color={"white"}
                      fontSize={16}
                      fontFamily={"Montserrat"}
                      bgGradient="linear(to-r, rgba(0, 200, 220, 1), rgba(0, 220, 255, 1), rgba(102, 245, 255, 1), rgba(180, 250, 255, 1))"
                      bgClip="text"
                      backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                      animation={`${gradientAnimation} 5s ease infinite`}
                      cursor={"help"}
                    >
                      Престижность
                    </Text>
                  </VStack>
                </Tooltip>
                <Tooltip
                  label=" Антивандальное исполнение  в закалённом стекле."
                  placement="bottom"
                  bg="rgba(28, 28, 28, 1)"
                  color="white"
                  openDelay={300}
                  closeDelay={200}
                  fontSize={16}
                  fontFamily={"Montserrat"}
                >
                  <VStack
                    position={"absolute"}
                    bottom={"5vh"}
                    left={"50vw"}
                    padding={"5px 10px"}
                    borderRadius={"12px"}
                  >
                    <Text
                      fontWeight={600}
                      color={"white"}
                      fontSize={16}
                      fontFamily={"Montserrat"}
                      bgGradient="linear(to-r, rgba(0, 200, 220, 1), rgba(0, 220, 255, 1), rgba(102, 245, 255, 1), rgba(180, 250, 255, 1))"
                      bgClip="text"
                      backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                      animation={`${gradientAnimation} 5s ease infinite`}
                      cursor={"help"}
                    >
                      Защищенность
                    </Text>
                  </VStack>
                </Tooltip>
                <Tooltip
                  label="ИИ алгоритм анализирующий аудиторию и выдающий рекламные ролики вашим ЦА"
                  placement="bottom"
                  bg="rgba(28, 28, 28, 1)"
                  color="white"
                  openDelay={300}
                  closeDelay={200}
                  fontSize={16}
                  fontFamily={"Montserrat"}
                >
                  <VStack
                    position={"absolute"}
                    bottom={"6vh"}
                    left={"15vw"}
                    padding={"5px 10px"}
                    borderRadius={"12px"}
                  >
                    <Text
                      fontWeight={600}
                      color={"white"}
                      fontSize={16}
                      fontFamily={"Montserrat"}
                      bgGradient="linear(to-r, rgba(0, 200, 220, 1), rgba(0, 220, 255, 1), rgba(102, 245, 255, 1), rgba(180, 250, 255, 1))"
                      bgClip="text"
                      backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                      animation={`${gradientAnimation} 5s ease infinite`}
                      cursor={"help"}
                    >
                      Высокие охваты рекламы
                    </Text>
                  </VStack>
                </Tooltip>
              </VStack>
            </VStack>
          </Stack>
        ) : null}
        <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
        <Stack
          alignItems={"center"}
          alignSelf={"center"}
          position={"relative"}
          marginTop={"30px"}
          marginBottom={"30px"}
          width={"100%"}
          flexDirection={width >= 1440 ? "row" : "column"}
          padding={
            width >= 1440
              ? "70px"
              : ["20px", "30px", "40px", "50px", "60px", "70px"]
          }
          pos={"relative"}
          spacing={0}
          justify={"space-between"}
        >
          <Image
            src={blur}
            position={"absolute"}
            zIndex={-1}
            left={"-160px"}
            top={"50px"}
            transform={"rotate(-2deg)"}
          />
          {/* <AnimatedBlock> */}
          <VStack
            alignItems={"flex-start"}
            alignSelf={"flex-start"}
            position={"relative"}
            spacing={0}
          >
            <Image
              src={blur}
              position={"absolute"}
              zIndex={-1}
              left={"-160px"}
              top={"0px"}
              transform={"rotate(-2deg)"}
            />
            {/* <AnimatedBlock> */}
            <FadeInWrapper>
              <Text
                fontSize={
                  width >= 1440
                    ? "50px"
                    : ["34px", "38px", "42px", "46px", "50px"]
                }
                fontWeight={600}
                color={"white"}
                lineHeight={width >= 1440 ? "80px" : null}
                fontFamily={"Montserrat"}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
              >
                I-PANEL - умная платформа
                <br />
                для вашего бизнеса.
              </Text>
            </FadeInWrapper>

            {/* </AnimatedBlock> */}
            {/* <AnimatedBlock> */}
            <FadeInWrapper slow={true}>
              <Text
                width={width >= 1440 ? "570px" : null}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={500}
                color={"white"}
                fontFamily={"Montserrat"}
                marginTop={
                  width >= 1440
                    ? "50px"
                    : ["15px", "20px", "20px", "20px", "20px"]
                }
              >
                Виджеты превращают экран в мощный информационный центр, который
                показывает актуальные данные в реальном времени.
                <br />
                Удобный интерфейс, яркий контент и интерактивные функции создают
                вау-эффект и привлекают внимание, делая I-PANEL незаменимым
                инструментом для вашего успеха.
              </Text>
              <FadeInWrapper>
                <Text
                  fontWeight={600}
                  color={"white"}
                  lineHeight={width >= 1440 ? "80px" : null}
                  fontFamily={"Montserrat"}
                  bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                  bgClip="text"
                  backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                  animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
                  textAlign={"left"}
                >
                  Все возможности в одном I-PANEL
                </Text>
              </FadeInWrapper>

              {/* </AnimatedBlock> */}
              {/* <AnimatedBlock> */}
              <FadeInWrapper slow={true}>
                <Text
                  width={width >= 1440 ? "570px" : null}
                  fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                  fontWeight={500}
                  color={"white"}
                  fontFamily={"Montserrat"}
                  textAlign={"left"}
                >
                  I-PANEL эффективно работает для информирования, рекламы,
                  навигации и аналитики, создавая яркий и привлекательный
                  визуальный контент
                </Text>
              </FadeInWrapper>
            </FadeInWrapper>
          </VStack>
          <Image
            src={scheme}
            width={"38vw"}
            objectFit={"fill"}
            borderRadius={"12px"}
          />
        </Stack>
        <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
        <Stack
          padding={
            width >= 1440
              ? "40px 70px 0 70px"
              : [
                  "20px 20px 0 20px",
                  "30px 30px 0 30px",
                  "40px 40px 0 40px",
                  "50px 50px 0 50px",
                  "60px 60px 0 60px",
                  "70px 70px 0 70px",
                ]
          }
          flexDirection={"row"}
          justifyContent={"space-between"}
          position={"relative"}
          overflow={"hidden"}
        >
          <VStack
            alignItems={"flex-start"}
            position={"relative"}
            marginTop={"30px"}
          >
            <Image
              src={blur}
              position={"absolute"}
              zIndex={-1}
              left={"-160px"}
              top={"0px"}
              transform={"rotate(-2deg)"}
            />
            {/* <AnimatedBlock> */}
            <FadeInWrapper>
              <Text
                fontSize={
                  width >= 1440
                    ? "50px"
                    : ["34px", "38px", "42px", "46px", "50px"]
                }
                fontWeight={600}
                color={"white"}
                lineHeight={width >= 1440 ? "80px" : null}
                fontFamily={"Montserrat"}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
              >
                Чем мы занимаемся?
              </Text>
            </FadeInWrapper>

            {/* </AnimatedBlock> */}
            {/* <AnimatedBlock> */}
            <FadeInWrapper slow={true}>
              <Text
                width={width >= 1440 ? "370px" : null}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={500}
                color={"white"}
                fontFamily={"Montserrat"}
                marginTop={
                  width >= 1440
                    ? "50px"
                    : ["15px", "20px", "20px", "20px", "20px"]
                }
              >
                Наша компания занимается разработкой и обслуживанием умных
                панелей. Под брендом I-PANEL мы самостоятельно осуществлям их
                проектирование и сборку.
              </Text>
            </FadeInWrapper>

            {/* </AnimatedBlock> */}
            {/* <AnimatedBlock direction={"column"}> */}
            {/* <Text
            width={width >= 1440 ? "370px" : null}
            fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
            fontWeight={500}
            color={"white"}
            fontFamily={"Montserrat"}
            marginTop={
              width >= 1440 ? "50px" : ["15px", "20px", "20px", "20px", "20px"]
            }
          >
            С помощью собственного ПО делаем индивидуальный дизайн с различными
            виджетами и полезной информацией.
          </Text> */}
            {/* </AnimatedBlock> */}

            <Text
              marginTop={
                width >= 1440
                  ? "83px"
                  : ["23px", "33px", "43px", "53px", "63px", "73px", "83px"]
              }
              marginBottom={width >= 1440 ? "219px" : "40px"}
              color={"rgba(3, 48, 67, 1)"}
              fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
              fontWeight={600}
              width={"max-content"}
              borderRadius={"10px"}
              cursor={"pointer"}
              transition={"0.5s"}
              fontFamily="Montserrat"
              border={"1px solid transparent"}
              padding={"10px 20px"}
              bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
              bgSize="200% 200%"
              animation={`${gradientAnimation} 8s ease infinite`}
              _hover={{
                backgroundColor: "transparent",
                bgGradient:
                  "linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))",
                bgClip: "text",
                color: "rgba(93, 191, 196, 1)",
                transition: "0.5s",
              }}
              onClick={() => scrollToSection(section2Ref)}
            >
              Подробнее
            </Text>
          </VStack>
          {width >= 1440 ? (
            <VStack alignItems={"center"} gap={"40px"} marginBottom={"40px"}>
              <Text
                color={"white"}
                fontSize={
                  width >= 1440
                    ? "30px"
                    : ["18px", "21px", "24px", "27px", "30px"]
                }
                fontFamily="Montserrat"
                ref={width >= 1440 ? section1Ref : null}
              >
                1
              </Text>
              <Stack bgColor={"white"} height={"100%"} width={"1px"} />
            </VStack>
          ) : null}
          {width >= 1440 ? (
            <Image
              className="imageAnima"
              src={blur}
              position={"absolute"}
              zIndex={1}
              left={width >= 1440 ? "520px" : 0}
              bottom={"-100px"}
              transform={"rotate(-35deg)"}
            />
          ) : null}

          {width >= 1440 ? (
            <>
              <FadeInFromBottom
                position={"absolute"}
                right={"603px"}
                bottom={"244px"}
                zIndex={2}
                opacity={0}
              >
                <Image
                  className="imageAnima"
                  src={clock}
                  // animation={fade}
                  __css={{ animation: `${fade}`, animationDelay: "6s" }}
                  transform={"rotate(-30deg)"}
                />
              </FadeInFromBottom>
              <FadeInFromBottom
                position={"absolute"}
                right={"334px"}
                bottom={"400px"}
                zIndex={2}
                opacity={0}
                delay={0.3}
              >
                <Image
                  src={date4}
                  transform={"rotate(-30deg)"}
                  // animation={fade}
                  __css={{ animation: `${fade}`, animationDelay: "0.1s" }}
                />
              </FadeInFromBottom>
              <FadeInFromBottom
                position={"absolute"}
                right={"778px"}
                bottom={"80px"}
                zIndex={2}
                opacity={0}
                delay={0.1}
              >
                <Image
                  src={usdeur2}
                  transform={"rotate(-30deg)"}
                  // animation={fade}
                  __css={{ animation: `${fade}`, animationDelay: "0.2s" }}
                />
              </FadeInFromBottom>
              <FadeInFromBottom
                position={"absolute"}
                right={"498px"}
                bottom={"209px"}
                zIndex={2}
                opacity={0}
                delay={0.4}
              >
                <Image
                  src={usdeur1}
                  transform={"rotate(-30deg)"}
                  // animation={fade}
                  __css={{ animation: `${fade}`, animationDelay: "0.3s" }}
                />
              </FadeInFromBottom>
              <FadeInFromBottom
                position={"absolute"}
                right={"190px"}
                bottom={"300px"}
                zIndex={2}
                opacity={0}
                delay={0.2}
              >
                <Image
                  src={date3}
                  transform={"rotate(-30deg)"}
                  // animation={fade}
                  __css={{ animation: `${fade}`, animationDelay: "0.4s" }}
                />
              </FadeInFromBottom>
              <FadeInFromBottom
                position={"absolute"}
                bottom={"55px"}
                right={"672px"}
                zIndex={2}
                opacity={0}
                delay={0.5}
              >
                <Image
                  src={date2}
                  transform={"rotate(-30deg)"}
                  // animation={fade}
                  __css={{ animation: `${fade}`, animationDelay: "0.5s" }}
                />
              </FadeInFromBottom>
              <FadeInFromBottom
                position={"absolute"}
                right={"332px"}
                bottom={"227px"}
                zIndex={2}
                opacity={0}
                delay={0.4}
              >
                <Image
                  src={weather2}
                  transform={"rotate(-30deg)"}
                  // animation={fade}
                  __css={{ animation: `${fade}`, animationDelay: "0.6s" }}
                />
              </FadeInFromBottom>
              <FadeInFromBottom
                position={"absolute"}
                right={"389px"}
                bottom={"0"}
                zIndex={2}
                opacity={0}
                delay={0.2}
              >
                <Image
                  src={time}
                  transform={"rotate(-30deg)"}
                  // animation={fade}
                  __css={{ animation: `${fade}`, animationDelay: "0.7s" }}
                />
              </FadeInFromBottom>

              <FadeInFromBottom
                position={"absolute"}
                right={"117px"}
                bottom={"208px"}
                zIndex={2}
                opacity={0}
                delay={0.3}
              >
                <Image
                  src={date1}
                  transform={"rotate(-30deg)"}
                  // animation={fade}
                  __css={{ animation: `${fade}`, animationDelay: "0.8s" }}
                />
              </FadeInFromBottom>
              <FadeInFromBottom
                position={"absolute"}
                bottom={-20}
                right={"174px"}
                zIndex={2}
                opacity={0}
                delay={0.1}
              >
                <Image
                  className="imageAnima"
                  src={weather1}
                  transform={"rotate(-30deg)"}
                  // animation={fade}
                  __css={{ animation: `${fade}`, animationDelay: "0.9s" }}
                />
              </FadeInFromBottom>
            </>
          ) : null}
        </Stack>
        <Stack
          bgColor={"white"}
          height={"1px"}
          width={"100%"}
          zIndex={2}
          marginBottom={["20px", "40px", "60px"]}
        />

        <Stack
          bgColor={"rgba(168, 226, 245, 1)"}
          width={"100%"}
          padding={
            width >= 1440
              ? "70px 140px"
              : [
                  "20px 40px",
                  "30px 60px",
                  "40px 80px",
                  "50px 100px",
                  "60px 120px",
                  "70px 140px",
                ]
          }
          gap={width >= 1440 ? "40px" : "10px"}
          alignItems={"center"}
          flexDirection={width >= 1440 ? "row" : "column"}
          bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
          bgSize="200% 200%"
          animation={`${gradientAnimation} 8s ease infinite`}
          zIndex={3}
        >
          <Image
            src={elevator}
            width={
              width >= 1440
                ? "200px"
                : [
                    "100px",
                    "115px",
                    "130px",
                    "145px",
                    "160px",
                    "175px",
                    "190px",
                    "200px",
                  ]
            }
          />
          <VStack
            alignItems={width >= 1440 ? "flex-start" : "center"}
            width={["90%", "500px", "600px", "800px"]}
          >
            {/* ы */}
            <Text
              marginTop={width >= 1440 ? "30px" : ["20px", "25px", "30px"]}
              fontFamily={"Montserrat"}
              color={"black"}
              fontWeight={"500"}
              textAlign={width >= 1440 ? "left" : "center"}
              fontSize={
                width >= 1440
                  ? "30px"
                  : ["18px", "21px", "22px", "22px", "22px"]
              }
            >
              Не тратьте свое время на поиск информации, для вашего удобства мы
              сделали это за вас, осталось только найти нужный виджет!
            </Text>
          </VStack>
        </Stack>
        <Swiper
          modules={[Autoplay, FreeMode]}
          freeMode={false}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          style={{ display: "flex", width: "100%" }}
          slidesPerView={
            width >= 1440 ? 4 : width >= 990 ? 3 : width >= 480 ? 2 : 1
          }
        >
          <SwiperSlide>
            <Image src={render1} objectFit={"cover"} h={"700px"}/>
          </SwiperSlide>
          <SwiperSlide>
            <Image src={render2} objectFit={"cover"} h={"700px"}/>
          </SwiperSlide>
          <SwiperSlide>
            <Image src={render3} objectFit={"cover"} h={"700px"}/>
          </SwiperSlide>
          <SwiperSlide>
            <Image src={render4} objectFit={"cover"} h={"700px"}/>
          </SwiperSlide>
          <SwiperSlide>
            <Image src={render5} objectFit={"cover"} h={"700px"}/>
          </SwiperSlide>
          <SwiperSlide>
            <Image src={render6} objectFit={"cover"} h={"700px"}/>
          </SwiperSlide>
        </Swiper>
        <Stack
          bgColor={"white"}
          height={"1px"}
          width={"100%"}
          zIndex={2}
          marginTop={["20px", "40px", "60px"]}
        />
        <Stack
          padding={
            width >= 1440
              ? "40px 70px 0 70px"
              : [
                  "20px 20px 0 20px",
                  "30px 30px 0 30px",
                  "40px 40px 0 40px",
                  "50px 50px 0 50px",
                  "60px 60px 0 60px",
                  "70px 70px 0 70px",
                ]
          }
          flexDirection={width >= 1440 ? "row" : "column"}
          justifyContent={"space-between"}
          position={"relative"}
        >
          <VStack alignItems={"flex-start"} position={"relative"}>
            <Image
              src={blur}
              position={"absolute"}
              zIndex={1}
              left={"-300px"}
              bottom={"-200px"}
              transform={"rotate(41deg)"}
            />
            <FadeInWrapper>
              <Text
                fontSize={
                  width >= 1440
                    ? "40px"
                    : ["28px", "32px", "34px", "36px", "40px"]
                }
                fontWeight={600}
                color={"white"}
                lineHeight={width >= 1440 ? "80px" : null}
                fontFamily={"Montserrat"}
                bgGradient="linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
              >
                Преимущества наших экранов
              </Text>
            </FadeInWrapper>

            <HStack
              gap={width >= 1440 ? "40px" : "15px"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              marginTop={
                width >= 1440
                  ? "70px"
                  : ["20px", "30px", "40px", "50px", "60px", "70px"]
              }
            >
              {width >= 980 ? (
                <Image
                  src={toggleIcon}
                  width={
                    width >= 1440 ? "70px" : ["40px", "50px", "60px", "70px"]
                  }
                  marginTop={"15px"}
                />
              ) : null}

              <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
                <FadeInWrapper slow={true}>
                  <Text
                    fontSize={
                      width >= 1440
                        ? "25px"
                        : ["18px", "19px", "20px", "23px", "25px"]
                    }
                    fontWeight={600}
                    color={"white"}
                    fontFamily={"Montserrat"}
                    lineHeight={"auto"}
                  >
                    Возможность персонализации
                  </Text>
                </FadeInWrapper>
                <FadeInWrapper slow={true}>
                  <Text
                    fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                    fontWeight={500}
                    color={"white"}
                    fontFamily={"Montserrat"}
                    width={["90%", "80%", "70%", "50%", "70%", "384px"]}
                  >
                    Интуитивная платформа с современным интерфейсом.
                  </Text>
                </FadeInWrapper>
                <FadeInWrapper slow={true}>
                  <Text
                    fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                    fontWeight={500}
                    color={"white"}
                    fontFamily={"Montserrat"}
                    marginTop={"10px"}
                    width={["90%", "80%", "70%", "50%", "70%", "384px"]}
                  >
                    Встроенный редактор: создавайте стильные дизайны за пару
                    минут.
                  </Text>
                </FadeInWrapper>
              </VStack>
            </HStack>

            <HStack
              gap={width >= 1440 ? "40px" : "15px"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              marginTop={
                width >= 1440
                  ? "70px"
                  : ["20px", "30px", "40px", "50px", "60px", "70px"]
              }
            >
              {width >= 980 ? (
                <Image
                  src={cpu}
                  width={
                    width >= 1440 ? "70px" : ["40px", "50px", "60px", "70px"]
                  }
                  marginTop={"15px"}
                />
              ) : null}
              <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
                <FadeInWrapper slow={true}>
                  <Text
                    fontSize={
                      width >= 1440
                        ? "25px"
                        : ["18px", "19px", "20px", "23px", "25px"]
                    }
                    fontWeight={600}
                    color={"white"}
                    fontFamily={"Montserrat"}
                  >
                    Удобство использования
                  </Text>
                </FadeInWrapper>
                <FadeInWrapper slow={true}>
                  <Text
                    width={["90%", "80%", "70%", "50%", "70%", "384px"]}
                    fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                    fontWeight={500}
                    color={"white"}
                    fontFamily={"Montserrat"}
                  >
                    Подробная аналитика и отчеты прямо в личном кабинете.
                  </Text>
                </FadeInWrapper>
                <FadeInWrapper slow={true}>
                  <Text
                    width={["90%", "80%", "70%", "50%", "70%", "384px"]}
                    fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                    fontWeight={500}
                    color={"white"}
                    fontFamily={"Montserrat"}
                  >
                    Управление несколькими панелями одновременно.
                  </Text>
                </FadeInWrapper>
                <FadeInWrapper slow={true}>
                  <Text
                    width={["90%", "80%", "70%", "50%", "70%", "384px"]}
                    fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                    fontWeight={500}
                    color={"white"}
                    fontFamily={"Montserrat"}
                    marginTop={"10px"}
                  >
                    Гибкие настройки: назначайте операторов для отдельных
                    панелей или групп.
                  </Text>
                </FadeInWrapper>
              </VStack>
            </HStack>

            <HStack
              gap={width >= 1440 ? "40px" : "15px"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              marginTop={
                width >= 1440
                  ? "70px"
                  : ["20px", "30px", "40px", "50px", "60px", "70px"]
              }
            >
              {width >= 980 ? (
                <Image
                  src={motherBoard}
                  width={
                    width >= 1440 ? "70px" : ["40px", "50px", "60px", "70px"]
                  }
                  marginTop={"15px"}
                />
              ) : null}
              <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
                <FadeInWrapper slow={true}>
                  <Text
                    fontSize={
                      width >= 1440
                        ? "25px"
                        : ["18px", "19px", "20px", "23px", "25px"]
                    }
                    fontWeight={600}
                    color={"white"}
                    fontFamily={"Montserrat"}
                  >
                    Все для пользователя
                  </Text>
                </FadeInWrapper>
                <FadeInWrapper slow={true}>
                  <Text
                    width={["90%", "80%", "70%", "50%", "70%", "384px"]}
                    fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                    fontWeight={500}
                    color={"white"}
                    fontFamily={"Montserrat"}
                  >
                    Различные тарифы управления - управляйте сами или доверьте
                    это нам
                  </Text>
                </FadeInWrapper>
                <FadeInWrapper slow={true}>
                  <Text
                    width={["90%", "80%", "70%", "50%", "70%", "384px"]}
                    fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                    fontWeight={500}
                    color={"white"}
                    fontFamily={"Montserrat"}
                    marginBottom={width >= 1440 ? "132px" : "40px"}
                  >
                    Поддержка 24/7 — всегда на связи, когда это нужно.
                  </Text>
                </FadeInWrapper>
              </VStack>
            </HStack>
          </VStack>
          {width >= 1440 ? (
            <VStack alignItems={"center"} gap={"40px"} marginBottom={"40px"}>
              <Text
                color={"white"}
                fontSize={
                  width >= 1440
                    ? "30px"
                    : ["18px", "21px", "24px", "27px", "30px"]
                }
                fontFamily="Montserrat"
                ref={width >= 1440 ? section2Ref : null}
              >
                2
              </Text>
              <Stack bgColor={"white"} height={"100%"} width={"1px"} />
            </VStack>
          ) : null}

          <Image
            src={theme}
            position={width >= 1440 ? "absolute" : null}
            bottom={width >= 1440 ? 0 : null}
            right={width >= 1440 ? "140px" : null}
            width={"484px"}
            alignSelf={"flex-end"}
          />
          <Image
            src={blur}
            position={"absolute"}
            zIndex={1}
            right={"100px"}
            bottom={"-100px"}
            transform={"rotate(-35deg)"}
            overflow={"hidden"}
          />
        </Stack>
        <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />

        <Stack
          padding={
            width >= 1440
              ? "70px"
              : ["20px", "30px", "40px", "50px", "60px", "70px"]
          }
          // flexDirection={"row"}
          gap={
            width >= 1440
              ? "70px"
              : ["20px", "30px", "40px", "50px", "60px", "70px"]
          }
          justifyContent={"space-between"}
        >
          <Carousel />
          {/* {width >= 1440 ? <Image src={render1} objectFit={"contain"} /> : null}

          <VStack align={"flex-start"} position={"relative"}>
            <FadeInWrapper>
              <Text
                color={"white"}
                fontSize={
                  width >= 1440
                    ? "40px"
                    : ["32px", "34px", "35px", "37px", "40px"]
                }
                fontWeight={600}
                fontFamily={"Montserrat"}
                lineHeight={"normal"}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
              >
                Где вы можете использовать наши панели
              </Text>
            </FadeInWrapper>

            <FadeInWrapper slow={true}>
              <Text
                color={"white"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={600}
                fontFamily={"Montserrat"}
                marginTop={"20px"}
              >
                Установка наших панелей максимально полезна в общественных
                пространствах. Холлы, лифты - вот идеальные места для размещения
                I-PANEL.
              </Text>
            </FadeInWrapper>
            <Image
              src={blur}
              position={"absolute"}
              zIndex={1}
              left={width >= 1440 ? "366px" : 0}
              width={"50%"}
              bottom={"0px"}
              transform={"rotate(11deg)"}
            />
          </VStack>
          {width >= 1440 ? (
            <VStack alignItems={"center"} gap={"20px"}>
              <Stack bgColor={"white"} height={"100%"} width={"1px"} />
            </VStack>
          ) : null} */}
        </Stack>
        <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
        <VoiceAssistant sended={sended} />
        <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />

        <Stack
          padding={
            width >= 1440
              ? "40px 70px"
              : [
                  "20px 20px",
                  "30px 30px",
                  "40px 40px",
                  "50px 50px",
                  "60px 60px",
                  "70px 70px",
                ]
          }
          flexDirection={"row"}
          justifyContent={"space-between"}
          position={"relative"}
        >
          <VStack
            alignItems={"flex-start"}
            align={"flex-start"}
            position={"relative"}
          >
            <Image
              src={blur}
              position={"absolute"}
              zIndex={1}
              left={"-160px"}
              top={"0px"}
              transform={"rotate(-2deg)"}
            />
            <FadeInWrapper>
              <Text
                fontSize={
                  width >= 1440
                    ? "50px"
                    : ["34px", "38px", "42px", "46px", "50px"]
                }
                fontWeight={600}
                color={"white"}
                lineHeight={width >= 1440 ? "80px" : null}
                fontFamily={"Montserrat"}
                marginTop={"30px"}
                bgClip="text"
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgSize="200% 200%"
                animation={`${gradientAnimation} 8s ease infinite`}
                _hover={{
                  backgroundColor: "transparent",
                  bgGradient:
                    "linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))",
                  bgClip: "text",
                  color: "rgba(93, 191, 196, 1)",
                  transition: "0.5s",
                }}
              >
                Подберем тему экрана на любой вкус
              </Text>
            </FadeInWrapper>
            <FadeInWrapper>
              <Text
                width={width >= 1440 ? "585px" : null}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={500}
                color={"white"}
                fontFamily={"Montserrat"}
                marginTop={width >= 1440 ? "57px" : "20px"}
              >
                Настройте внешний вид так, как нравится вам. Благодаря редактору
                виджетов I-PANEL вы можете подобрать наиболее подходящий дизайн
                экрана и виджетов.
              </Text>
            </FadeInWrapper>
            {width >= 1440 ? (
              <HStack marginTop={"20px"} position={"relative"}>
                <Image src={all_themes} />
                {/* <Image
                  src={imageLift1}
                  position={"absolute"}
                  left={5}
                  top={190}
                  right={1}
                  marginLeft={"auto"}
                  marginRight={"auto"}
                  width="177px"
                  h={"315px"}
                /> */}
                <video
                  poster={imageLift1}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 15,
                    right: 1,
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "13vw",
                    height: "500px",
                    objectFit: "cover",
                  }}
                  muted
                  loop
                  autoPlay
                  src={video51}
                ></video>
              </HStack>
            ) : null}
            <VStack width={"100%"} align={"center"} alignItems={"center"}>
              <Button
                color={"rgba(3, 48, 67, 1)"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={600}
                backgroundColor={"rgba(93, 191, 196, 1)"}
                width={width >= 1440 ? "auto" : "auto"}
                borderRadius={"10px"}
                textAlign={"center"}
                cursor={"pointer"}
                transition={"0.5s"}
                alignSelf={width >= 1920 ? "center" : "flex-start"}
                fontFamily="Montserrat"
                // margin={"40px auto"}
                marginTop={"40px"}
                zIndex={2}
                padding={"10px 20px"}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgSize="200% 200%"
                animation={`${gradientAnimation} 8s ease infinite`}
                _hover={{
                  backgroundColor: "transparent",
                  bgGradient:
                    "linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))",
                  bgClip: "text",
                  color: "rgba(93, 191, 196, 1)",
                  transition: "0.5s",
                }}
                // onClick={() => scrollToSection(section2Ref)}
              >
                <a href="editor.html">
                  {width >= 1440 ? `Попробуйте редактор тем` : `Попробовать`}
                </a>
              </Button>
            </VStack>
          </VStack>
          {width >= 1440 ? (
            <VStack alignItems={"center"} gap={"40px"}>
              <Text
                color={"white"}
                fontSize={
                  width >= 1440
                    ? "30px"
                    : ["18px", "21px", "24px", "27px", "30px"]
                }
                fontFamily="Montserrat"
                ref={section3Ref}
              >
                3
              </Text>
              <Stack bgColor={"white"} height={"100%"} width={"1px"} />
            </VStack>
          ) : null}
        </Stack>
        <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
        <Stack
          alignItems={"center"}
          alignSelf={"center"}
          position={"relative"}
          marginTop={"30px"}
          marginBottom={"30px"}
          width={"100%"}
          flexDirection={width >= 1440 ? "row" : "column"}
          padding={
            width >= 1440
              ? "70px"
              : ["20px", "30px", "40px", "50px", "60px", "70px"]
          }
          pos={"relative"}
          spacing={0}
          justify={"space-between"}
        >
          <Image
            src={blur}
            position={"absolute"}
            zIndex={-1}
            left={"-160px"}
            top={"50px"}
            transform={"rotate(-2deg)"}
          />
          {/* <AnimatedBlock> */}
          <VStack
            alignItems={"flex-start"}
            alignSelf={"flex-start"}
            position={"relative"}
            spacing={0}
          >
            <Image
              src={blur}
              position={"absolute"}
              zIndex={-1}
              left={"-160px"}
              top={"0px"}
              transform={"rotate(-2deg)"}
            />
            {/* <AnimatedBlock> */}
            <FadeInWrapper>
              <Text
                fontSize={
                  width >= 1440
                    ? "50px"
                    : ["34px", "38px", "42px", "46px", "50px"]
                }
                fontWeight={600}
                color={"white"}
                lineHeight={width >= 1440 ? "80px" : null}
                fontFamily={"Montserrat"}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
              >
                Преимущества I-PANEL
              </Text>
            </FadeInWrapper>

            {/* </AnimatedBlock> */}
            {/* <AnimatedBlock> */}
            <FadeInWrapper>
              <Text
                fontWeight={600}
                color={"white"}
                fontFamily={"Montserrat"}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
                textAlign={"left"}
              >
                Привлекает внимание:
              </Text>
            </FadeInWrapper>

            {/* </AnimatedBlock> */}
            {/* <AnimatedBlock> */}
            <FadeInWrapper slow={true}>
              <Text
                width={width >= 1440 ? "570px" : null}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={500}
                color={"white"}
                fontFamily={"Montserrat"}
                textAlign={"left"}
              >
                Узкий вытянутый формат выделяется среди других экранов, сразу
                захватывая взгляд.
              </Text>
            </FadeInWrapper>
            <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
            <FadeInWrapper>
              <Text
                fontWeight={600}
                color={"white"}
                fontFamily={"Montserrat"}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
                textAlign={"left"}
              >
                Гибкость установки:
              </Text>
            </FadeInWrapper>

            {/* </AnimatedBlock> */}
            {/* <AnimatedBlock> */}
            <FadeInWrapper slow={true}>
              <Text
                width={width >= 1440 ? "570px" : null}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={500}
                color={"white"}
                fontFamily={"Montserrat"}
                textAlign={"left"}
              >
                Идеально подходит для узких и длинных пространств — вдоль
                коридоров, на колоннах или витринах, не нарушая интерьер.
              </Text>
            </FadeInWrapper>
            <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
            <FadeInWrapper>
              <Text
                fontWeight={600}
                color={"white"}
                fontFamily={"Montserrat"}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
                textAlign={"left"}
              >
                Элегантный дизайн:
              </Text>
            </FadeInWrapper>

            {/* </AnimatedBlock> */}
            {/* <AnimatedBlock> */}
            <FadeInWrapper slow={true}>
              <Text
                width={width >= 1440 ? "570px" : null}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={500}
                color={"white"}
                fontFamily={"Montserrat"}
                textAlign={"left"}
              >
                Стильные и минималистичные панели дополняют любой современный
                интерьер, создавая атмосферу технологий и стиля.
              </Text>
            </FadeInWrapper>
            <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
            <FadeInWrapper>
              <Text
                fontWeight={600}
                color={"white"}
                fontFamily={"Montserrat"}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
                textAlign={"left"}
              >
                Динамичный контент:
              </Text>
            </FadeInWrapper>

            {/* </AnimatedBlock> */}
            {/* <AnimatedBlock> */}
            <FadeInWrapper slow={true}>
              <Text
                width={width >= 1440 ? "570px" : null}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={500}
                color={"white"}
                fontFamily={"Montserrat"}
                textAlign={"left"}
              >
                Панорамные видео и мультимедиа легко сочетаются с полезными
                виджетами — от часов и погоды до уведомлений — на одном экране.
              </Text>
            </FadeInWrapper>
            <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
            <FadeInWrapper>
              <Text
                fontWeight={600}
                color={"white"}
                fontFamily={"Montserrat"}
                bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
                textAlign={"left"}
              >
                Эффект бесшовности:
              </Text>
            </FadeInWrapper>

            {/* </AnimatedBlock> */}
            {/* <AnimatedBlock> */}
            <FadeInWrapper slow={true}>
              <Text
                width={width >= 1440 ? "570px" : null}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={500}
                color={"white"}
                fontFamily={"Montserrat"}
                textAlign={"left"}
              >
                Панели можно соединять в длинные ряды, создавая масштабные и
                впечатляющие инсталляции.
              </Text>
            </FadeInWrapper>
          </VStack>
          <video
            src={video52}
            muted
            height={"540px"}
            style={{ maxHeight: "540px", maxWidth: "38vw" }}
            autoPlay
            loop
          />
        </Stack>
        <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
        <Stack
          padding={
            width >= 1440
              ? "70px"
              : ["20px", "30px", "40px", "50px", "60px", "70px"]
          }
          flexDirection={"row"}
          gap={
            width >= 1440
              ? "70px"
              : ["20px", "30px", "40px", "50px", "60px", "70px"]
          }
          justifyContent={"space-between"}
        >
          <Stack
            align={"flex-start"}
            position={"relative"}
            gap={
              width >= 1440
                ? "70px"
                : ["20px", "30px", "40px", "50px", "60px", "70px"]
            }
            flexDirection={width >= 1440 ? "row" : "column"}
            justify={"space-between"}
            width={"100%"}
          >
            <VStack
              align={"flex-start"}
              gap={
                width >= 1440
                  ? "70px"
                  : ["20px", "30px", "40px", "50px", "60px", "70px"]
              }
              width={width >= 1440 ? "364px" : null}
            >
              <FadeInWrapper slow={true}>
                <Text
                  fontFamily={"Montserrat"}
                  fontSize={
                    width >= 1440
                      ? "44px"
                      : ["32px", "35px", "38px", "41px", "44px"]
                  }
                  fontWeight={"600"}
                  color={"white"}
                  bgGradient="linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                  bgClip="text"
                  backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                  animation={`${gradientAnimation} 5s ease infinite`} // Применяем анимацию
                >
                  Протестируйте камеру
                </Text>
              </FadeInWrapper>
              <FadeInWrapper>
                <Text
                  fontFamily={"Montserrat"}
                  fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                  fontWeight={"600"}
                  color={"white"}
                >
                  Здесь вы можете протестировать работу системы распознавания
                  лиц, установленную в наших лифтах
                </Text>
              </FadeInWrapper>
              {width < 1440 ? <WebcamComponent /> : null}
              {/* <FadeInWrapper>
                <Text
                  marginTop={width >= 1440 ? "50px" : "20px"}
                  color={"rgba(3, 48, 67, 1)"}
                  fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                  fontWeight={600}
                  backgroundColor={"rgba(93, 191, 196, 1)"}
                  width={"max-content"}
                  borderRadius={"10px"}
                  cursor={"pointer"}
                  transition={"0.5s"}
                  fontFamily="Montserrat"
                  padding={"10px 20px"}
                  bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                  bgSize="200% 200%"
                  animation={`${gradientAnimation} 8s ease infinite`}
                  _hover={{
                    backgroundColor: "transparent",
                    bgGradient:
                      "linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))",
                    bgClip: "text",
                    color: "rgba(93, 191, 196, 1)",
                    transition: "0.5s",
                  }}
                  // onClick={() => scrollToSection(section2Ref)}
                >
                  Посмотреть темы экранов
                </Text>
              </FadeInWrapper> */}
            </VStack>
            {width >= 1440 ? <WebcamComponent /> : null}
          </Stack>
          {width >= 1440 ? (
            <VStack alignItems={"center"}>
              <Stack bgColor={"white"} height={"100%"} width={"1px"} />
            </VStack>
          ) : null}
        </Stack>
        <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
        <Stack
          padding={
            width >= 1440
              ? "40px 70px"
              : ["20px", "30px", "40px", "50px", "60px", "70px"]
          }
          flexDirection={"row"}
          justifyContent={"space-between"}
          position={"relative"}
        >
          <VStack
            alignItems={"flex-start"}
            position={"relative"}
            width={"100%"}
          >
            <Image
              src={blur}
              position={"absolute"}
              zIndex={1}
              left={"-160px"}
              top={"0px"}
              transform={"rotate(-2deg)"}
            />
            <FadeInWrapper>
              <Text
                fontSize={
                  width >= 1440
                    ? "50px"
                    : ["34px", "38px", "42px", "46px", "50px"]
                }
                fontWeight={600}
                color={"white"}
                lineHeight={width >= 1440 ? "80px" : null}
                fontFamily={"Montserrat"}
                bgGradient="linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                bgClip="text"
                backgroundSize="200% 200%" // Увеличиваем размер фона для анимации
                animation={`${gradientAnimation} 8s ease infinite`} // Применяем анимацию
              >
                Наши мониторы
              </Text>
            </FadeInWrapper>
            <Stack
              gap={"40px"}
              marginY={width >= 1440 ? "40px" : "20px"}
              flexDirection={width >= 1440 ? "row" : "column"}
            >
              <FadeInWrapper slow={true}>
                <Text
                  width={width >= 1440 ? "370px" : null}
                  fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                  fontWeight={500}
                  color={"white"}
                  fontFamily={"Montserrat"}
                >
                  Компания lift brand - Здесь вы можете ознакомиться с
                  вариантами панелей, которые у нас представлены. Ориентация
                  экранов - ориентация экрана или просто ориентация.
                </Text>
              </FadeInWrapper>
            </Stack>
            <Swiper
              modules={[Autoplay, FreeMode]}
              freeMode={false}
              // autoplay={{ delay: 3000, disableOnInteraction: false }}
              style={{
                display: "flex",
                width: "100%",
                marginRight: 0,
              }}
              slidesPerView={
                width >= 1440 ? 4 : width >= 990 ? 3 : width >= 480 ? 2 : 1
              }
            >
              <SwiperSlide>
                <Monitor
                  model={`I-PANEL 340-1200_28,6"`}
                  ratio="16:4.5"
                  panel_size="340x1200"
                  resolution="540x1920"
                  size={`28.6"`}
                  orientation="Вертикальная/горизонтальная"
                  price="195 000 - 225 000"
                />
              </SwiperSlide>
              <SwiperSlide>
                <Monitor
                  model={`I-PANEL 480-1400_34"`}
                  ratio="21:9"
                  panel_size="480x1400"
                  resolution="2560x1080"
                  size={`34"`}
                  orientation="Горизонтальная"
                  price="205 000 - 255 000"
                />
              </SwiperSlide>
              <SwiperSlide>
                <Monitor
                  model={`I-PANEL 400-1200_37"`}
                  ratio="16:4.5"
                  panel_size="400x1200"
                  resolution="540x1920"
                  size={`37"`}
                  orientation="Вертикальная /горизонтальная"
                  price="235 000 - 275 000"
                />
              </SwiperSlide>
              <SwiperSlide>
                <Monitor
                  model={`I-PANEL 400-2200_74"x2`}
                  ratio="16:4.5"
                  panel_size="400x2200"
                  resolution="540x1920"
                  size={`74" (37 + 37)`}
                  orientation="Вертикальная /горизонтальная"
                  price="395 000 - 455 000"
                />
              </SwiperSlide>
              <SwiperSlide>
                <Monitor
                  model={`I-PANEL 340-2200_28,6"L`}
                  ratio="16:4.5"
                  panel_size="340x2200"
                  resolution="540x1920"
                  size={`28.6"`}
                  orientation="Вертикальная"
                  price="225 000 - 245 000"
                />
              </SwiperSlide>
            </Swiper>
            <HStack
              width={"100%"}
              paddingRight={width >= 1440 ? "70px" : null}
              justifyContent={"space-between"}
              marginTop={
                width >= 1440
                  ? "70px"
                  : ["20px", "30px", "40px", "50px", "60px", "70px"]
              }
              align={"flex-start"}
            >
              <VStack
                align={"flex-start"}
                width={width >= 1440 ? null : "100%"}
              >
                <Text
                  fontFamily={"Montserrat"}
                  fontSize={
                    width >= 1440
                      ? "30px"
                      : ["18px", "21px", "24px", "27px", "30px"]
                  }
                  color={"white"}
                  fontWeight={"600"}
                >
                  Количество панелей
                </Text>
                <HStack
                  gap={width >= 1440 ? "20px" : "10px"}
                  marginTop={width >= 1440 ? "20px" : "10px"}
                >
                  <Stack
                    bgColor={"rgba(93, 191, 196, 1)"}
                    width={width >= 1440 ? "50px" : "35px"}
                    height={width >= 1440 ? "50px" : "35px"}
                    borderRadius={"10px"}
                    justify={"center"}
                    align={"center"}
                    cursor={selected[0][0] == 0 ? "no-drop" : "pointer"}
                    onClick={() => {
                      copy_selected[0][0] != 0
                        ? (copy_selected[0][0] -= 1)
                        : (copy_selected[0][0] = 0);
                      setSelected(copy_selected);
                    }}
                  >
                    <Image
                      src={minusIcon}
                      width={width >= 1440 ? "24px" : "14px"}
                    />
                  </Stack>
                  <Stack
                    width={"100px"}
                    height={width >= 1440 ? "50px" : "35px"}
                    border={"1px solid white"}
                    borderRadius={"10px"}
                    justify={"center"}
                    align={"center"}
                  >
                    <Text
                      fontFamily={"Montserrat"}
                      fontSize={
                        width > 1440 ? "14px" : ["14px", "14px", "14px"]
                      }
                      fontWeight={"600"}
                      color={"white"}
                    >
                      {selected[0][0]}
                    </Text>
                  </Stack>
                  <Stack
                    bgColor={"rgba(93, 191, 196, 1)"}
                    width={width >= 1440 ? "50px" : "35px"}
                    height={width >= 1440 ? "50px" : "35px"}
                    borderRadius={"10px"}
                    justify={"center"}
                    align={"center"}
                    cursor={"pointer"}
                    onClick={() => {
                      copy_selected[0][0] += 1;
                      setSelected(copy_selected);
                    }}
                  >
                    <Image
                      src={plusIcon}
                      width={width >= 1440 ? "24px" : "14px"}
                    />
                  </Stack>
                </HStack>
                {/* <Text
                fontFamily={"Montserrat"}
                fontSize={
                  width >= 1440
                    ? "30px"
                    : ["18px", "21px", "24px", "27px", "30px"]
                }
                color={"white"}
                fontWeight={"600"}
                marginTop={
                  width >= 1440
                    ? "70px"
                    : ["20px", "30px", "40px", "50px", "60px", "70px"]
                }
              >
                Ориентация экранов
              </Text>
              <Stack
                gap={"20px"}
                marginTop={"20px"}
                flexDirection={width >= 1440 ? "row" : "column"}
                width={width >= 1440 ? null : "100%"}
              >
                <Stack
                  height={"50px"}
                  border={"1px solid white"}
                  borderRadius={"10px"}
                  justify={"center"}
                  align={"center"}
                  padding={"0 20px"}
                >
                  <Text
                    color={"white"}
                    fontFamily={"Montserrat"}
                    fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                    fontWeight={"600"}
                  >
                    Вертикальная
                  </Text>
                </Stack>
                <Stack
                  height={"50px"}
                  border={"1px solid white"}
                  borderRadius={"10px"}
                  justify={"center"}
                  align={"center"}
                  padding={"0 20px"}
                >
                  <Text
                    color={"white"}
                    fontFamily={"Montserrat"}
                    fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                    fontWeight={"600"}
                  >
                    Горизонтальная
                  </Text>
                </Stack>
              </Stack> */}
                <VStack
                  as="form"
                  onSubmit={(event) => {
                    handleSubmit(event);
                  }}
                  align={"flex-start"}
                >
                  <Text
                    fontFamily="Montserrat"
                    fontSize={width >= 1440 ? "30px" : fontSize}
                    color="white"
                    fontWeight="600"
                    marginTop={width >= 1440 ? "70px" : marginTop}
                  >
                    Комментарий
                  </Text>

                  <Textarea
                    color="white"
                    width={width >= 1440 ? "551px" : "100%"}
                    height="167px"
                    name="message"
                  />

                  <Text
                    fontFamily="Montserrat"
                    fontSize={width >= 1440 ? "30px" : fontSize}
                    color="white"
                    fontWeight="600"
                  >
                    Ваша почта
                  </Text>

                  <Input
                    name="email"
                    type="email"
                    color="white"
                    width={width >= 1440 ? "551px" : "100%"}
                  />

                  <Button
                    disabled={sended}
                    type="submit"
                    marginTop={width >= 1440 ? "50px" : marginTop}
                    color="rgba(3, 48, 67, 1)"
                    fontSize="14px"
                    fontWeight="600"
                    width="max-content"
                    borderRadius="10px"
                    cursor="pointer"
                    transition="0.5s"
                    fontFamily="Montserrat"
                    border="1px solid transparent"
                    padding="10px 20px"
                    bgGradient="linear(to-r, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))"
                    bgSize="200% 200%"
                    _hover={{
                      backgroundColor: "transparent",
                      bgGradient:
                        "linear(to-l, rgba(93, 191, 196, 1), rgba(173, 221, 247, 1), rgba(180, 235, 258, 1))",
                      bgClip: "text",
                      color: "rgba(93, 191, 196, 1)",
                      transition: "0.5s",
                    }}
                  >
                    Отправить заявку
                  </Button>
                </VStack>
              </VStack>
              {width >= 1440 ? <Image src={slide4Lift} /> : null}
            </HStack>
          </VStack>
          {width >= 1440 ? (
            <VStack alignItems={"center"} gap={"40px"}>
              <Text
                color={"white"}
                fontSize={
                  width >= 1440
                    ? "30px"
                    : ["18px", "21px", "24px", "27px", "30px"]
                }
                fontFamily="Montserrat"
                ref={section4Ref}
              >
                4
              </Text>
              <Stack bgColor={"white"} height={"100%"} width={"1px"} />
            </VStack>
          ) : null}
        </Stack>
        <Stack bgColor={"white"} height={"1px"} width={"100%"} zIndex={2} />
        <VStack
          padding={
            width >= 1440
              ? "70px"
              : ["20px", "30px", "40px", "50px", "60px", "70px"]
          }
          backgroundColor={"rgba(13, 13, 13, 1)"}
          align={"flex-start"}
        >
          <Text
            color={"white"}
            fontSize={
              width >= 1440 ? "30px" : ["18px", "21px", "24px", "27px", "30px"]
            }
            fontWeight={"700"}
            fontFamily="Montserrat"
          >
            Наши контакты
          </Text>
          <Stack
            marginTop={
              width >= 1440
                ? "50px"
                : ["20px", "30px", "40px", "50px", "50px", "50px"]
            }
            gap={
              width >= 1440
                ? "50px"
                : ["20px", "30px", "40px", "50px", "50px", "50px"]
            }
            flexDirection="row"
          >
            <VStack gap={"0px"} align={"flex-start"}>
              <Text
                color={"white"}
                fontFamily={"Montserrat"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
              >
                Телефон
              </Text>
              <Text
                color={"white"}
                fontFamily={"Montserrat"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={"700"}
              >
                +7 (906) 733-99-00
              </Text>
            </VStack>
            <VStack gap={"0px"} align={"flex-start"}>
              <Text
                color={"white"}
                fontFamily={"Montserrat"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
              >
                WhatsApp
              </Text>
              <Text
                color={"white"}
                fontFamily={"Montserrat"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={"700"}
              >
                +7(903) 617-43-93
              </Text>
            </VStack>
            <VStack gap={"0px"} align={"flex-start"}>
              <Text
                color={"white"}
                fontFamily={"Montserrat"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
              >
                Telegram bot
              </Text>
              <Text
                color={"white"}
                fontFamily={"Montserrat"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={"700"}
              >
                +7(903) 617-43-93
              </Text>
            </VStack>
            <VStack gap={"0px"} align={"flex-start"}>
              <Text
                color={"white"}
                fontFamily={"Montserrat"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
              >
                Адрес
              </Text>
              <Text
                color={"white"}
                fontFamily={"Montserrat"}
                fontSize={width > 1440 ? "14px" : ["14px", "14px", "14px"]}
                fontWeight={"700"}
              >
                г. Красногорск, б-р Космонавтов, дом 12 корп 1, пом. 2
              </Text>
            </VStack>
          </Stack>
          <HStack
            gap={
              width >= 1440
                ? "50px"
                : ["20px", "30px", "40px", "50px", "50px", "50px"]
            }
            marginTop={
              width >= 1440
                ? "50px"
                : ["20px", "30px", "40px", "50px", "50px", "50px"]
            }
          >
            <Image
              src={liftBrand}
              width={width >= 1440 ? "307px" : ["187px"]}
            />
            <Image src={iPanel} width={width >= 1440 ? "193px" : ["133px"]} />
          </HStack>
        </VStack>
      </Stack>
    </VStack>
  );
};
export default MainPage;
