import React, { useState, useRef, useEffect } from "react";
import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import p12 from "./../images/12.png"; // Подставьте свой путь к изображению
import p13 from "./../images/13.png";
import p15 from "./../images/15.png";
import p17 from "./../images/17.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import "swiper/css";

// Массив с данными для слайдов
const slides = [
  {
    image: p12,
  },
  {
    image: p13,
  },
  {
    image: p15,
  },
  {
    image: p17,
  },
];
const height = 400;

const Carousel = () => {
  return (
    <Swiper
      modules={[Autoplay, FreeMode]}
      freeMode={false}
      autoplay={{ delay: 5000, disableOnInteraction: false }}
      direction={"vertical"}
      style={{
        // display: "flex",
        width: "100%",
        // justifyContent: "center",
        // alignItems: "center",
        height: 400,
      }}
      slidesPerView={1}
    >
      <SwiperSlide>
        <Image src={p12} objectFit={"cover"} height={height} w={"100%"} />
      </SwiperSlide>
      <SwiperSlide>
        <Image src={p13} objectFit={"cover"} height={height} w={"100%"} />
      </SwiperSlide>
      <SwiperSlide>
        <Image src={p15} objectFit={"cover"} height={height} w={"100%"} />
      </SwiperSlide>
      <SwiperSlide>
        <Image src={p17} objectFit={"cover"} height={height} w={"100%"} />
      </SwiperSlide>
    </Swiper>
  );
};

export default Carousel;
