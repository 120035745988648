import { HStack, Image, Stack, Text } from "@chakra-ui/react";

import logo from "../images/logo.svg";
import BurgerMenu from "./burger_menu";
import useWindowDimensions from "./windowDimensions";

const Header = ({ padd }) => {
  const width = useWindowDimensions();
  return (
    <Stack
      flexDirection={"row"}
      width={"100%"}
      height={"100px"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={"46px"}
      padding={
        width > 1440
          ? "0 70px"
          : ["0 20px", "0 30px", "0 40px", "0 50px", "0 60px", "0 70px"]
      }
      borderBottom={"1px solid white"}
      zIndex={2}
    >
      <HStack gap={"20px"}>
        {width >= 1440 ? (
          <Text
            color={"white"}
            fontFamily={"Montserrat"}
            fontSize={width > 1440 ? "16px" : ["14px", "15px", "16px"]}
            paddingLeft={padd}
          >
            Договориться
          </Text>
        ) : null}

        <Text
          cursor={"pointer"}
          fontFamily={"Montserrat"}
          fontSize={width > 1440 ? "16px" : ["14px", "15px", "16px"]}
          color={"white"}
          fontWeight={"700"}
          width={"max-content"}
        >
          +7 (906) 733-99-00
        </Text>
      </HStack>
      <HStack gap={width >= 1440 ? "45px" : "10px"}>
        <Image src={logo} />
        {/* <BurgerMenu /> */}
      </HStack>
    </Stack>
  );
};

export default Header;
